import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps, JSX } from 'solid-js';

type Props = ParentProps<
	JSX.HTMLAttributes<HTMLDivElement> & {
		size?: 'default' | 'small' | 'xsmall';
	}
>;

export function Content(props: Props) {
	return (
		<div
			{...props}
			class={twMerge(
				twJoin(
					'mx-auto w-full px-4 sm:px-6 md:px-12',
					'container',
					props.size === 'small' && 'max-w-screen-lg',
					props.size === 'xsmall' && 'max-w-screen-sm',
				),
				props.class,
			)}
		/>
	);
}
